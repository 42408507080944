<template>
    <div class="overlay" @click.self="$emit('close')">
        <div class="select-list h-60">
            <div class="d-flex-row jc-between">
                <h5>할부기간 선택</h5>
                <button type="button" class="btn-close" @click="$emit('close')"><i class="icon-close"></i></button>
            </div>
            <ul class="content-list">
                <li v-for="(period, index) in periods" :key="index" :class="{ 'li_padding' : installmentLimit >= Number(period.installment)}">
                    <a role="button" @click="selected(period)" v-if="installmentLimit >= Number(period.installment)">
                        <span>{{ period.name }}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "InstallmentPeriodSelect",
    props: ['periods'],
    data() {
        return {
            installmentLimit: 12,
        }
    },
    mounted() {
        this.getInstallmentInfo()
    },
    methods: {
        getInstallmentInfo() {
            this.$get(this.$PAYMENT_HANDWRITTEN_INSTALLMENT, 'get_installment_info', (result) => {
                console.log(result, 'installment')
                this.installmentLimit = result.data.installmentLimit
            }, (result) => {
                this.httpError(result)
            })
        },
        selected(_period) {
            this.$emit('selected', _period)
        }
    }
}
</script>

<style scoped>
.select-list > ul > li {
    padding-bottom: 0;
}
.li_padding {
    padding-bottom: 30px !important;
}
</style>
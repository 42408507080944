import { render, staticRenderFns } from "./ChangeRentName.vue?vue&type=template&id=85c8d58e&scoped=true&"
import script from "./ChangeRentName.vue?vue&type=script&lang=js&"
export * from "./ChangeRentName.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85c8d58e",
  null
  
)

export default component.exports